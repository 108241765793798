import React from 'react';
import './Menu.css';
import logo from '../assets/logo-8.png';
import WhatsAppButton from './WhatsAppButton';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from "react-router-dom";

function Menu() {
    function toggleMenu(event) {
        if (event.type === 'touchstart') event.preventDefault();
        const nav = document.getElementById('nav');
        nav.classList.toggle('active');
        const active = nav.classList.contains('active');
        event.currentTarget.setAttribute('aria-expanded', active);
        if (active) {
            event.currentTarget.setAttribute('aria-label', 'Fechar Menu');
        } else {
            event.currentTarget.setAttribute('aria-label', 'Abrir Menu');
        }
    }

    return (
        // <header id="header">
        //     <a id="logo" href="">
        //         <img src={logo} alt="Logo" className='logo'/>
        //     </a>
        //     <nav id="nav">
        //     <button aria-label="Abrir Menu" id="btn-mobile" aria-haspopup="true" aria-controls="menu" aria-expanded="false" onClick={toggleMenu}>Menu
        //         <span id="hamburger"></span>
        //     </button>
        //     <ul id="menu" role="menu">
        //         <li><a href="/">Home</a></li>
        //         <li><a href="/credenciadas">Credenciadas</a></li>
        //         <li><a href="/servicos">Serviços</a></li>
        //         <li><a href="/contato">Contato</a></li>
        //         <li className='whatsapp'><WhatsAppButton size={'small'} title={"Fale com a gente"}/></li>
        //     </ul>
        //     </nav>
        // </header>
        <header id="header">
            <a id="logo" href="/">
                <img src={logo} alt="Logo" className='logo'/>
            </a>
            <nav id="nav">
            <button aria-label="Abrir Menu" id="btn-mobile" aria-haspopup="true" aria-controls="menu" aria-expanded="false" onClick={toggleMenu}>
                <span id="hamburger"></span>
            </button>
            <ul id="menu" role="menu">
                <NavItem className='itemNav'>
                    <NavLink href="#">
                    <Link to='/'>Home</Link>
                    </NavLink>
                </NavItem>
                {/* <NavItem className='itemNav'>
                    <NavLink href="#">
                    <Link to='/credenciadas'>Credenciadas</Link>
                    </NavLink>
                </NavItem> */}
                <NavItem className='itemNav'>
                    <NavLink href="#">
                    <Link to='/servicos'>Serviços</Link>
                    </NavLink>
                </NavItem>
                <NavItem className='itemNav'>
                    <NavLink href="#">
                    <Link to='/especialidades'>Especialidades</Link>
                    </NavLink>
                </NavItem>
                <NavItem className='itemNav'>
                    <NavLink href="#">
                    <Link to='/pmoc'>PMOC</Link>
                    </NavLink>
                </NavItem>
                <NavItem className='itemNav'>
                    <NavLink href="#">
                    <Link to='/contato'>Contato</Link>
                    </NavLink>
                </NavItem>
                <NavItem className='itemNav'>
                    <NavLink href="#">
                    <Link to='/team'>Equipe</Link>
                    </NavLink>
                </NavItem>
                <NavItem className='whatsapp'>
                    <NavLink href="#">
                    <WhatsAppButton size={'small'} title={"Fale com a gente"}/>
                    </NavLink>
                </NavItem>
            </ul>
            </nav>
        </header>

    )
}

export default Menu;